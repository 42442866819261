/*
* Based on Skeleton by Dave Gamache (www.getskeleton.com)
* Modified by Vasterad
*/


/* Table of Contents
==================================================
    #Base (1180 Grid)
    #Desktop (960 Grid)
    #Tablet (Portrait)
    #Mobile (Portrait)
    #Mobile (Landscape)
    #Clearing */
    
    
/* #Base (1180 Grid)
================================================== */

    .container                                  { position: relative; width: 1200px; margin: 0 auto; padding: 0; }
    .container .column,
    .container .columns                         { float: left; display: inline; margin-left: 10px; margin-right: 10px; }
    .row                                        { margin-bottom: 20px; }

    /* Nested Column Classes */
    .column.alpha, .columns.alpha               { margin-left: 0; }
    .column.omega, .columns.omega               { margin-right: 0; }

    /* Base Grid */
    .container .one.column,
    .container .one.columns                     { width: 55px;  }
    .container .two.columns                     { width: 130px; }
    .container .three.columns                   { width: 205px; }
    .container .four.columns                    { width: 280px; }
    .container .five.columns                    { width: 355px; }
    .container .six.columns                     { width: 430px; }
    .container .seven.columns                   { width: 505px; }
    .container .eight.columns                   { width: 580px; }
    .container .nine.columns                    { width: 655px; }
    .container .ten.columns                     { width: 730px; }
    .container .eleven.columns                  { width: 805px; }
    .container .twelve.columns                  { width: 880px; }
    .container .thirteen.columns                { width: 955px; }
    .container .fourteen.columns                { width: 1030px; }
    .container .fifteen.columns                 { width: 1105px; }
    .container .sixteen.columns                 { width: 1180px; }

    .container .one-third.column                { width: 380px; }
    .container .two-thirds.column               { width: 780px; }

    .container .twelve.sidebar-right.columns    { width: 860px; margin-right: 30px; }
    .container .twelve.sidebar-left.columns     { width: 860px; margin-left: 30px; }

    .container .four.shop.columns               { width: 273px; margin: 0 0 0 20px; }
    .container .four.masonry-item.columns       { width: 273px; margin: 0 20px 0 0; }

    body.boxed,
    .fullwidth-element { width: 1290px; }
    .fullwidth .parallax-content { width: 1180px; }
   
   .fullwidth .fullwidth-element.home-slider { width: 100% }
    
    @media only screen and (min-width: 1290px) and (max-width: 1390px) {
        body.boxed,
        .fullwidth-element,
        .fullwidth-element img                  { width: 100% }
    }
    


/* #Dekstop (960 Grid)
================================================== */

    /* Note: Design for a width of 960px */

    @media only screen and (min-width: 960px) and (max-width: 1389px) {
        .container                                  { position: relative; width: 960px; margin: 0 auto; padding: 0; }
        .container .column,
        .container .columns                         { float: left; display: inline; margin-left: 10px; margin-right: 10px; }
        .row                                        { margin-bottom: 20px; }

        /* Nested Column Classes */
        .column.alpha, .columns.alpha               { margin-left: 0; }
        .column.omega, .columns.omega               { margin-right: 0; }

        /* Base Grid */
        .container .one.column,
        .container .one.columns                     { width: 40px;  }
        .container .two.columns                     { width: 100px; }
        .container .three.columns                   { width: 160px; }
        .container .four.columns                    { width: 220px; }
        .container .five.columns                    { width: 280px; }
        .container .six.columns                     { width: 340px; }
        .container .seven.columns                   { width: 400px; }
        .container .eight.columns                   { width: 460px; }
        .container .nine.columns                    { width: 520px; }
        .container .ten.columns                     { width: 580px; }
        .container .eleven.columns                  { width: 640px; }
        .container .twelve.columns                  { width: 700px; }
        .container .thirteen.columns                { width: 760px; }
        .container .fourteen.columns                { width: 820px; }
        .container .fifteen.columns                 { width: 880px; }
        .container .sixteen.columns                 { width: 940px; }

        .container .one-third.column                { width: 300px; }
        .container .two-thirds.column               { width: 620px; }

        .container .twelve.sidebar-right.columns    { width: 680px; margin-right: 30px; }
        .container .twelve.sidebar-left.columns     { width: 680px; margin-left: 30px; }

        .container .four.shop.columns               { width: 213px; margin: 0 0 0 20px; }
        .container .four.masonry-item.columns       { width: 213px; margin: 0 20px 0 0; }
        
        body.boxed,
        .fullwidth-element                          { width: 1020px; }
        .fullwidth .parallax-content                { width: 940px; }
        .fullwidth .fullwidth-element.home-slider   { width: 940px; }


    }
    
    @media only screen and (min-width: 769px) and (max-width: 1040px) {
        body.boxed,
        .fullwidth-element                          { width: 100%; }
    }



/* #Tablet (Portrait)
================================================== */

    /* Note: Design for a width of 768px */

    @media only screen and (min-width: 768px) and (max-width: 990px) {
        .container                                  { width: 768px; }
        .container .column,
        .container .columns                         { margin-left: 10px; margin-right: 10px;  }
        .column.alpha, .columns.alpha               { margin-left: 0; margin-right: 10px; }
        .column.omega, .columns.omega               { margin-right: 0; margin-left: 10px; }
        .alpha.omega                                { margin-left: 0; margin-right: 0; }

        .container .one.column,
        .container .one.columns                     { width: 28px; }
        .container .two.columns                     { width: 76px; }
        .container .three.columns                   { width: 124px; }
        .container .four.columns                    { width: 172px; }
        .container .five.columns                    { width: 220px; }
        .container .six.columns                     { width: 268px; }
        .container .seven.columns                   { width: 316px; }
        .container .eight.columns                   { width: 364px; }
        .container .nine.columns                    { width: 412px; }
        .container .ten.columns                     { width: 460px; }
        .container .eleven.columns                  { width: 508px; }
        .container .twelve.columns                  { width: 556px; }
        .container .thirteen.columns                { width: 604px; }
        .container .fourteen.columns                { width: 652px; }
        .container .fifteen.columns                 { width: 700px; }
        .container .sixteen.columns                 { width: 748px; }

        .container .one-third.column                { width: 236px; }
        .container .two-thirds.column               { width: 492px; }

        .container .twelve.sidebar-right.columns    { width: 546px; margin-right: 20px; }
        .container .twelve.sidebar-left.columns     { width: 546px; margin-left: 20px; }

        .container .four.shop.columns               { width: 258px; margin: 0 0 0 20px; }
        .container .four.masonry-item.columns       { width: 258px; margin: 0 20px 0 0; }

        body.boxed,
        .fullwidth-element                          { width: 100% }
        .fullwidth .parallax-content                { width: 748px; }
        .fullwidth .fullwidth-element.home-slider   { width: 100% }

    }


/*  #Mobile (Portrait)
================================================== */

    /* Note: Design for a width of 320px */

    @media only screen and (max-width: 767px) {
        .container { width: 300px; }
        .container .columns,
        .container .column { margin: 0; }

        .container .one.column,
        .container .one.columns,
        .container .two.columns,
        .container .three.columns,
        .container .four.columns,
        .container .five.columns,
        .container .six.columns,
        .container .seven.columns,
        .container .eight.columns,
        .container .nine.columns,
        .container .ten.columns,
        .container .eleven.columns,
        .container .twelve.columns,
        .container .thirteen.columns,
        .container .fourteen.columns,
        .container .fifteen.columns,
        .container .sixteen.columns,
        .container .one-third.column,
        .container .two-thirds.column,
        .container .twelve.sidebar-right.columns,
        .container .twelve.sidebar-left.columns,
        .container .five.sidebar-right.columns,
        .container .eleven.sidebar-right.columns { width: 300px; }
        
        .container .four.shop.columns           { width: 300px; margin: 0; }
        .container .four.masonry-item.columns   { width: 300px; margin: 0; }

        .container .twelve.sidebar-left.columns { margin-left: 0; }

        .container .fourteen.carousel.columns  { width: 240px; }
        .container .one.carousel.column  { width: 30px; }
        
        body.boxed,
        .fullwidth-element,
        .fullwidth .fullwidth-element.home-slider { width: 100% }

        .fullwidth .parallax-content     { width: 300px; }

        #googlemaps {
            height: 200px !important;
            width: 100%;
        }
    }


/* #Mobile (Landscape)
================================================== */

    /* Note: Design for a width of 480px */

    @media only screen and (min-width: 480px) and (max-width: 767px) {
        .container { width: 420px; }
        .container .columns,
        .container .column { margin: 0; }

        .container .one.column,
        .container .one.columns,
        .container .two.columns,
        .container .three.columns,
        .container .four.columns,
        .container .five.columns,
        .container .six.columns,
        .container .seven.columns,
        .container .eight.columns,
        .container .nine.columns,
        .container .ten.columns,
        .container .eleven.columns,
        .container .twelve.columns,
        .container .thirteen.columns,
        .container .fourteen.columns,
        .container .fifteen.columns,
        .container .sixteen.columns,
        .container .one-third.column,
        .container .two-thirds.column,
        .container .twelve.sidebar-right.columns,
        .container .twelve.sidebar-left.columns,
        .container .five.sidebar-right.columns,
        .container .eleven.sidebar-right.columns { width: 420px; }
        
        .container .twelve.sidebar-left.columns { margin-left: 0; }
        .container .four.shop.columns           { width: 420px; margin: 0; }
        .container .four.masonry-item.columns   { width: 420px; margin: 0; }

        .container .fourteen.carousel.columns  { width: 340px; }
        .container .one.carousel.column  { width: 40px; }
        
        body.boxed,
        .fullwidth-element,
        .fullwidth .fullwidth-element.home-slider { width: 100% }

        .fullwidth .parallax-content   { width: 420px; }

    }


/* #Clearing
================================================== */

    /* Self Clearing Goodness */
    .container:after { content: "\0020"; display: block; height: 0; clear: both; visibility: hidden; }

    /* Use clearfix class on parent to clear nested columns,
    or wrap each row of columns in a <div class="row"> */
    .clearfix:before,
    .clearfix:after,
    .row:before,
    .row:after {
      content: '\0020';
      display: block;
      overflow: hidden;
      visibility: hidden;
      width: 0;
      height: 0; }
    .row:after,
    .clearfix:after {
      clear: both; }
    .row,
    .clearfix {
      zoom: 1; }

    /* You can also use a <br class="clear" /> to clear columns */
    .clear {
      clear: both;
      display: block;
      overflow: hidden;
      visibility: hidden;
      width: 0;
      height: 0;
    }
