/*
* Based on Skeleton by Dave Gamache (www.getskeleton.com)
* Modified by Vasterad
*/


/* Table of Content
==================================================
	#Reset & Basics
	#Basic Styles
	#Site Styles
	#Typography
	#Links
	#Lists
	#Images
	#Buttons
	#Forms
	#Misc */

/* #Reset & Basics (Inspired by E. Meyers)
================================================== */
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline; }
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
	display: block; }
body {
	line-height: 1; }
ol, ul {
	list-style: none; }
blockquote, q {
	quotes: none; }
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none; }
table {
	border-collapse: collapse;
	border-spacing: 0; }
.no-select {
	user-select: none;
	-o-user-select:none;
	-moz-user-select: none;
	-khtml-user-select: none;
	-webkit-user-select: none; }


/* #Basic Styles
================================================== */
body {
	background: #fff;
	font-size: 14px;
	line-height: 24px;
	color: #888;
	-webkit-font-smoothing: antialiased; /* Fix for webkit rendering */
	-webkit-text-size-adjust: 100%;
}

#wrapper {
	padding: 0;
	background-color: #fff;
}

/* Boxed Layout Style */
body.boxed {
	margin: 0 auto;
	background: #e9e9e9;
}

#header {
	margin-left: auto;
	margin-right: auto;
	left: 0;
	right: 0;
}


/* Main Font */
body, h1, h2, h3, h4, h5, h6,
input[type="button"], input[type="submit"],
input[type="text"], input[type="password"], input[type="email"], input[type="tel"], textarea, select, button {
	font-family: "Open Sans", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
}


/* Basic Alginment
================================================== */
#wrapper img {
	height: auto;
	max-width: 100%;
}

.columns.google-map img {
	max-width: none;
}

img, object, video {
	height: auto;
	display:block;
}

img {
	width: auto;
	border: 0;
	-ms-interpolation-mode: bicubic;
}

/* Flexible Embeds */
.embed {
	position: relative;
	padding: 0px;
	padding-bottom: 56.25%; /* 16/9 ratio */
	height: 0;
	overflow: hidden;
}

.embed iframe,
.embed object,
.embed embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.image-left {
	float: left;
	margin: 5px 15px 8px 0;
}

.image-right {
	float:right;
	margin: 5px 0 8px 15px;
}

.image-right,
.image-left {
	padding: 5px;
	border: 1px solid #e0e0e0;
	max-width: 100%;
	-webkit-box-shadow:  0px 2px 0px 0px rgba(0, 0, 0, 0.03);
	box-shadow:  0px 2px 0px 0px rgba(0, 0, 0, 0.03);
}


/* #Margin Styles
================================================== */

/* Margin Reset */
.margin-reset { margin: 0 !important; }

/* Margin Top */
.margin-top-0 { margin-top: 0 !important; }
.margin-top-5 { margin-top: 5px !important; }
.margin-top-10 { margin-top: 10px !important; }
.margin-top-15 { margin-top: 15px !important; }
.margin-top-20 { margin-top: 20px !important; }
.margin-top-25 { margin-top: 25px !important; }
.margin-top-30 { margin-top: 30px !important; }
.margin-top-35 { margin-top: 35px !important; }
.margin-top-40 { margin-top: 40px !important; }
.margin-top-45 { margin-top: 45px !important; }
.margin-top-50 { margin-top: 50px !important; }

/* Margin Bottom */
.margin-bottom-0 { margin-bottom: 0 !important; }
.margin-bottom-5 { margin-bottom: 5px !important; }
.margin-bottom-10 { margin-bottom: 10px !important; }
.margin-bottom-15 { margin-bottom: 15px !important; }
.margin-bottom-20 { margin-bottom: 20px !important; }
.margin-bottom-25 { margin-bottom: 25px !important; }
.margin-bottom-30 { margin-bottom: 30px !important; }
.margin-bottom-35 { margin-bottom: 35px !important; }
.margin-bottom-40 { margin-bottom: 40px !important; }
.margin-bottom-45 { margin-bottom: 45px !important; }
.margin-bottom-50 { margin-bottom: 50px !important; }

/* Margin Left */
.margin-left-0 { margin-left: 0 !important; }

/* Margin Right */
.margin-right-5 { margin-right: 5px !important; }


/* #Typography
================================================== */
h1, h2, h3, h4, h5, h6 {
	color: #606060;
	font-weight: 500;
}

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a { font-weight: inherit; }
h1 { font-size: 28px; line-height: 50px; }
h2 { font-size: 24px; line-height: 30px; }
h3 { font-size: 20px; line-height: 34px; }
h4 { font-size: 18px; line-height: 30px; }
h5 { font-size: 16px; line-height: 24px; }
h6 { font-size: 14px; line-height: 21px; }

p { margin: 0 0 15px 0; line-height: 24px;}
p img { margin: 0; }
p.lead { font-size: 21px; line-height: 27px; color: #777;  }

em { font-style: italic; }
strong { font-weight: 600; color: #606060; }
small { font-size: 80%; }

hr { border: solid #ddd; border-width: 1px 0 0; clear: both; margin: 10px 0 30px; height: 0; }


/* #Links
================================================== */
a { color: #3acf87; text-decoration: none; outline: 0; -webkit-transition: color 0.2s ease-in-out; -moz-transition: color 0.2s ease-in-out; -o-transition: color 0.2s ease-in-out; -transition: color 0.2s ease-in-out; }
a:hover { color: #888; }
a:visited { color: transparent; }
p a, p a:visited { line-height: inherit; }


/* #Lists
================================================== */
ul, ol { margin: 0; }
ul { list-style: none outside; }
ol { list-style: decimal; }
ol, ul.square, ul.circle, ul.disc { margin-left: 30px; }
ul.square { list-style: square outside; }
ul.circle { list-style: circle outside; }
ul.disc { list-style: disc outside; }
ul ul, ul ol,
ol ol, ol ul {}
ul ul li, ul ol li,
ol ol li, ol ul li {}
li { line-height: 18px; }
ul.large li { line-height: 21px; }
li p { line-height: 21px; }


/* #Forms
================================================== */

input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"],
textarea,
.input-field,
select {
	padding: 10px 12px !important;
	outline: none;
	font-size: 13px;
	color: #999;
	margin: 0;
	max-width: 94%;
	display: block;
	background: #eee;
	border: 1px solid #eee;
	font-weight: 600;
	border-radius: 0 !important;
	height:45px;
	box-sizing: content-box !important;
}
select {
	padding: 0;}
input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="tel"],
textarea:focus {
	border: 1px solid #f2f2f2;
	background: #f2f2f2;
	color: #808080;
	-webkit-transition: all 0.1s ease-in-out;
	-moz-transition: all 0.1s ease-in-out;
	-o-transition: all 0.1s ease-in-out;
	-ms-transition: all 0.1s ease-in-out;
	transition: all 0.1s ease-in-out;
}
::-webkit-input-placeholder { /* WebKit browsers */
	color:    #999;
	opacity: 1;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
	color:    #999;
	opacity: 1;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
	color:    #999;
	opacity: 1;
}
:-ms-input-placeholder { /* Internet Explorer 10+ */
	color:    #999;
	opacity: 1;
}
textarea {
	min-height: 60px; }
label,
legend {
	display: block;
	font-weight: bold;
	font-size: 14px;
	font-weight: normal;
	margin-bottom: 5px;}
input {
	-webkit-transition: all 0.1s ease-in-out;
	-moz-transition: all 0.1s ease-in-out;
	-o-transition: all 0.1s ease-in-out;
	-ms-transition: all 0.1s ease-in-out;
	transition: all 0.1s ease-in-out;
}
input[type="checkbox"] {
	display: inline; }
label span,
legend span {
	font-weight: normal;
	font-size: 14px;
	color: #444; }
		
.button-style{
	background-color: #495d7f;
	color: #fff !important;
	border: none;
    font-weight: bold;
    text-transform: uppercase;
	cursor: pointer;
    text-align: center;
    padding: 10px;
	margin: 0 0 30px 0;
	border-radius: 0;
}


.cal_btn {
	width: 100% !important;
    height: 40px !important;
}

.account-content ul.order-total span {
	width:100px;
	display:inline-block;
	font-weight:bold;
	color:#555555;
}

/* ACCOUNT CONTENT */

.account-content {
	padding:0;
}

.account-content h3 {
    font-weight:600;
}

.account-content h3 span {
	font-weight:300;
}

.account-content p {
    font-size:15px;
    color:#777777;
}

.account-content b {
    font-size:15px;
    color:#777777;
}

.account-content h4 {
	margin:0 0 10px;
    font-size:18px;
    color:#666666;
	font-weight:600;
}

/* PROFILE */

.account-content .account-profile-top div {
	display:inline-block;
}

.account-content .account-profile-top div.button {
	float:right;
}

.account-content .account-profile-top img {
	margin-right:10px;
}

.account-content .account-profile-top h3 {
	position:relative;
	top:12px;
}

.account-content .account-profile-top h3 small {
	display:block;
	padding:5px 0 0 2px;
	color:#BBBBBB;
	font-size:60%;
}

.account-content .account-profile-top a {
	margin-top:19px;
}

.account-content .form-horizontal {
	clear:both;
	margin-top:20px;
}

/* ADDRESS */

.account-content .table td a.btn i {
	width:11px;
}

.account-content .table td a.btn {
	margin-right:5px;
}

.account-content .table td a.btn:last-child {
	margin-right:0;
}

/* SINGLE ORDER */

.account-content .order-status {
	margin:4px 0 14px;
	display:inline-block;
}

.account-content .order-action {
	float:right;
}

.account-content .order-action li {
	padding:0;
}

.account-content .products-order {
	border:2px solid #F0F0F0;
	padding:15px;
}

.account-content ul.order-total {
	text-align:right;
	margin:0;
}

.account-content ul.order-total li {
	padding:2px 0;
	font-size:16px;
}

.account-content ul.order-total span {
	width:100px;
	display:inline-block;
	font-weight:bold;
	color:#555555;
}

.account-content ul.order-total span.total {
	color:#E74C3C;
}

/* SHOPPING CART */

.account-content .shopping-cart {
	margin-bottom:3px;
}

.account-content .shopping-cart .update-cart {
	float:right;
}

/* CHECKOUT */

.account-content .checkout-step {
	position:relative;
	text-align:center;
	margin-bottom:15px;
}

.account-content .checkout-step .number {
	background-color:#F0F0F0;
	color:#AAAAAA;
	border-radius:50%;
	display:inline-block;
	height:29px;
	width:29px;
	font-weight:bold;
	font-size:20px;
}

.account-content .checkout-step.active .number {
	color:#FFFFFF;
}

.account-content .checkout-step .title {
	font-weight:600;
	font-size:16px;
	color:#888888;
	margin:7px 0 0;
}

.account-content .checkout-step.active .title {
	color:#555555;
}

.account-content .checkout-step:after {
	position:absolute;
	height:16px;
	width:16px;
	margin-left:-8px;
	left:50%;
	bottom:-24px;
	content:"";
	border-radius:50%;
	border:2px solid #F0F0F0;
	background-color:#FFFFFF;
	z-index:1;
}

.account-content .checkout-progress {
	position:relative;
	left:12.5%;
	width:75%;
	border-radius:0;
	background-color:#F0F0F0;
	box-shadow:none;
	height:2px;
	margin-bottom:40px;
}

.account-content .checkout-progress .progress-bar {
	box-shadow:none;
}

.account-content .products-order.checkout {
}

.checkout .addresses-toggle {
	margin-bottom:20px;
}

.billing-information textarea {
	height:130px;
}

.shipping-information .checkbox {
	margin-bottom:30px;
}

#shipping-address {
	display:none;
}

.account-content > * {
	box-sizing: content-box !important;
}

.account-content {
	box-sizing: content-box !important;
}


.account-content .products-order.shipping-method .radio,
.account-content .products-order.payment-method .radio {
    margin:15px 0;
	border:0;
	box-shadow:none;
}

.account-content .products-order.shipping-method .radio:first-child,
.account-content .products-order.payment-method .radio:first-child {
    margin-top:0;
}

.account-content .products-order.shipping-method .radio:last-child,
.account-content .products-order.payment-method .radio:last-child {
    margin-bottom:0;
}

.account-content .products-order.shipping-method .radio .panel-collapse,
.account-content .products-order.payment-method .radio .panel-collapse {
    margin-left:30px;
}

.account-content .products-order .radio label span {
    margin-left:25px;
	font-weight:400;
	color:#AAAAAA;
}

.account-content .products-order.shipping-method .radio .panel-collapse .radio {
     margin:7px 0;
}

.account-content .products-order.shipping-method .shipping-method-description {
    margin:10px 0 20px 30px;
}

.account-content .products-order.shipping-method .shipping-method-description p {
    margin:0 0 10px;
}

.account-content .products-order.shipping-method .shipping-method-description ul {
    margin:0;
}

.account-content .products-order.shipping-method .shipping-method-description ul li {
	font-weight:bold;   
}

.account-content .products-order.shipping-method .shipping-method-description ul li span {
	font-weight:400;
    width:70px;
	display:inline-block;
}

.account-content .products-order.payment-method .credit-card-form {
	padding-top:15px;
}

.account-content .products-order.payment-method .credit-card-form label {
	font-weight:700;
	padding-left:0;
}

#credit-card-img {
	padding:4px 8px;
	color:#999999;
}

#credit-card-img i {
	font-size:24px;
	width:35px;
}

#credit-card-img img {
	height:24px;
}

.expiration-date .form-control {
	width:49%;
	display:inline-block;
}

#credit-card-error p {
	margin:0;
	color:#E74C3C;
	font-weight:bold;
    font-size:12px;
}

.input-field {
    height: 18px !important;
}