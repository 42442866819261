/* ///////////////////////////////////// */
/* ///////   GENERIC STYLES  /////////// */
/* ///////////////////////////////////// */
.bg_color_orange,
.bg_color_orange_hover:hover,
.p_table.bg_color_orange_hover.active,
[class*="button_type_"].color_orange:not(.transparent),
[class*="button_type_"].color_orange.transparent:hover{
	background:#ffb347;	
}

a[ng-click] {
  cursor: pointer;
}
.no-margin {
  margin: 0 !important;
}
.btn-color {
  background-color: #bfa980 !important;
}
#logo {
  /*margin-top: 22px;*/
}
#logo a img {
  float: left;
  height: 75px;
}
ul.top-bar-menu li.contact-info {
  display: inline-block;
}
ul.top-bar-menu li.contact-info i {
  width: 10px;
}
.show-on-small,
.show-on-mini {
  display: none;
}
.titlebar .narrow-bc {
  padding-top: 1.5em;
}
/* @TODO this is not forever! Just for the demo */
.titlebar .narrow-bc h2 {
  display: none;
}
.pull-right {
  float: right !important;
}
.pull-left {
  float: left !important;
}
.cancel.button {
  background-color: #c0392b;
}
.cancel.button:hover {
  background-color: #e74c3c;
}
.button {
  -webkit-transition: background-color 0.2s ease-in-out;
  -moz-transition: background-color 0.2s ease-in-out;
  -ms-transition: background-color 0.2s ease-in-out;
  -o-transition: background-color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out;
}
.button:hover {
  background-color: #666;
}
.list-1 li,
.list-2 li,
.list-3 li,
.list-4 li {
  position: relative;
}
#call-us {
  margin-top: 19px;
  display: inline-block;
  /* margin-left: 2.1em; */
  margin-left:10px;
}
#call-us a,
#call-us i,
#call-us span {
  /* font-size: 25px; */
  font-size: 22px;
}
#call-us a {
  color: #444;
}
#call-us span,
#call-us i.fa {
  color: #aaa;
}
.text-center {
  text-align: center;
}
#navbar_cartandsearch .search-field {
  height: 18px !important;
}
input[disabled="disabled"],
a.disabled,
button.disabled,
button[disabled="disabled"],
select[disabled="disabled"],
textarea[disabled="disabled"] {
  cursor: not-allowed;
  /*pointer-events: none;*/
  opacity: 0.7;
}
/*TABS for Discount weeks and Discount bulk*/
.tab-group {
  border-bottom: 1px solid #eee;
}
.tab-group-tab {
  border-color: #c0c0c0;
  border-style: solid;
  border-width: 1px 1px 0;
  color: #666 !important;
  display: inline-block;
  height: 30px;
  line-height: 30px;
  margin-right: -5px;
  padding: 0.25em 1em;
}
.tab-group-tab.selected {
  background-color: #c0c0c0;
}

.modal {
  background-color: white;
  bottom: auto !important;
  left: 50% !important;
  margin-left: -25% !important;
  position: fixed;
  right: auto !important;
  top: 10% !important;
  width: 50%;
  z-index: 500;
  display: none;
}
.modal-dialog {
  margin: 0;
}

/* ///////////////////////////////////// */
/* ///////      LOADER       /////////// */
/* ///////////////////////////////////// */
.fullscreen-loader {
  display: block;
  position: fixed;
  height: 100%;
  width: 200%;
  background-color: #efefef;
  margin: 0;
  margin-left: -50%;
  z-index: 1000;
}
#inner-loader {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 8px -3px;
  left: 50%;
  margin-left: -110px;
  margin-top: -100px;
  padding: 20px;
  position: fixed;
  top: 50%;
  width: 180px;
  height: 175px;
  z-index: 1001;
}
#loader {
  color: white;
  left: 50%;
  margin-left: -60px;
  position: absolute;
  top: 47px;
}
#load-toolong {
  background-color: white;
  bottom: 15px;
  color: #444;
  font-size: 15px;
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
  display: none;
}
/* ///////////////////////////////////// */
/* ///////    HOME PAGE      /////////// */
/* ///////////////////////////////////// */
.showbiz {
  margin-bottom: 20px !important;
}
.homepage .fa.fa-home {
  bottom: -4px;
  font-size: 28px;
  margin: -10px 0;
  position: relative;
}

/* ///////////////////////////////////// */
/* ///////     RATING STARS  /////////// */
/* ///////////////////////////////////// */
.star-ratings-sprite {
  background: url("/img/system/rating.png") repeat-x;
  font-size: 0;
  height: 21px;
  line-height: 0;
  overflow: hidden;
  text-indent: -999em;
  width: 110px;
  margin: 0 auto;
  opacity: 0.8;
  box-sizing: content-box;
}
.star-ratings-sprite-rating {
  background: url("/img/system/rating.png") repeat-x;
  background-position: 0 100%;
  float: left;
  height: 21px;
  display: block;
}
.star-ratings-sprite.small-ratings {
  -webkit-transform: scale(0.5);
  -moz-transform: scale(0.5);
  -ms-transform: scale(0.5);
  -o-transform: scale(0.5);
  transform: scale(0.5);
}
.products.grid-view .star-ratings-sprite.ng-scope {
  bottom: 15px;
  left: 31%;
  position: absolute;
}

/* ///////////////////////////////////// */
/* ///////     PRODUCTS      /////////// */
/* ///////////////////////////////////// */

/* ///////// PRODUCT IMAGE ///////// */
/*img.grayscale {*/
/*-webkit-filter: grayscale(100%);*/
/*filter: grayscale(100%);*/
/*filter: url('/css/desaturate.svg#greyscale');*/
/*}*/
/*img.blur, figure:hover img.blur:hover {*/
/*filter: url('/css/blur.svg#blur');*/
/*-webkit-filter: blur(2px);*/
/*filter: blur(0px);*/
/*}*/
/*figure:hover img.blur {*/
/*filter: blur(2px);*/
/*-webkit-transition: filter 0.3s;*/
/*-moz-transition: filter 0.3s;*/
/*-ms-transition: filter 0.3s;*/
/*-o-transition: filter 0.3s;*/
/*transition: filter 0.3s;*/
/*}*/
figure:hover .product-code {
  opacity: 0;
}
.product-page .title > h1 {
  text-transform: uppercase;
}
.single-product .product-title {
  line-height: 35px;
}

/* ///////// PRODUCT PRICE ///////// */
.product .old-price,
.single-product .old-price {
  color: #aaa !important;
  text-decoration: line-through;
  font-size: 15px;
}
.product .discount,
.single-product .discount {
  color: darkred;
  font-size: 13px;
  text-decoration: none !important;
}
.old-price.minuscule {
  font-size: 10px;
}
.free-delivery {
  background: #278140 none repeat scroll 0 0;
  color: white;
  margin-left: 1em;
  padding: 2px;
}

/* ///////// PRODUCT CODE  ///////// */
.product-code {
  position: absolute;
  padding: 4px;
  right: 0;
  bottom: 0;
  font-size: 10px;
  z-index: 11;
  cursor: help;
}
.product-code.product-code-left {
  left: 0;
}
.product-code.outside {
  position: relative;
  display: block;
  clear: both;
  line-height: 10px;
  padding-left: 0;
}

/* ///////// PRODUCT BUTTONS /////// */
.button-cart-list {
  font-size: 20px !important;
  padding: 0.4em 0.6em;
}
/*.button-cart-list:hover {
        background-color: #aaa;
    }*/
/*.button.button-cart-list {
        bottom: 19px;
        padding: 1px 0 0 3px;
        position: absolute;
        right: 0;
    }*/

.product.product-in-grid .plusminusform {
  width: 90px;
  margin-left: -33px;
  left: 50%;
  position: absolute;
}
.product.product-in-grid .plusminusform .qtyminus,
.product.product-in-grid .plusminusform .qtyplus {
  /* height: 20px; */
  width: 20px;
}
.product.product-in-grid .plusminusform .qtyminus:before,
.product.product-in-grid .plusminusform .qtyplus:before {
  font-size: 12px;
  /* top: 1px; */
}
.product.product-in-grid .plusminusform .qty {
  padding: 0;
  height: 18px;
}

/** quick preview button **/
.linking a.button.qvw::before {
  background: rgba(0, 0, 0, 0.06) none repeat scroll 0 0;
  content: "";
  font-family: "FontAwesome";
  height: 100%;
  left: 0;
  padding: 8px 15px 8px 12px;
  position: absolute;
  top: 0;
}
.qty-checkout .qtyminus:before,
.qty-checkout .qtyplus:before {
  left: 0;
}
input.qty {
  width: 24px;
  height: 18px;
  margin-bottom: 0;
}

/* ///////// PRODUCT DETAILS /////// */
.item-description.small-font {
  font-size: 13px;
  text-transform: none !important;
  padding: 0 0 0 2.15em;
}
.item-description {
  background: none !important;
}
.item-description span {
  text-transform: none !important;
  color: #555;
  font-size: 15px;
  font-weight: normal;
}

.product-title-container {
  padding-left: 2em !important;
  display: inline-block;
}

/* ///////// MISCELLANEOUS ///////// */
.product-list-section {
  height: auto;
}
.product-list-section > * {
  text-align: left;
}
.star-container {
  width: 65%;
  display: inline-block;
  clear: none;
  margin-left: -28px;
}
.cart-item-name {
  /*width: 85%;*/
}

/* ///////////////////////////////////// */
/* ///////     DATEPICKER    /////////// */
/* ///////////////////////////////////// */
.datepicker-custom {
  position: absolute;
  background-color: rgb(239, 239, 239);
  z-index: 100;
}
.datepicker-custom table {
  border: 10px solid rgb(239, 239, 239);
}
.datepicker-custom .uib-left {
  margin: 5px 0 5px 5px;
}
.datepicker-custom .uib-right {
  margin: 5px 5px 5px 0;
}
.datepicker-custom .uib-title {
  left: 10%;
  position: relative;
  top: -17px;
  width: 80%;
}
.datepicker-custom .uib-spacing {
  margin: 2px 5px 0;
}

/* ///////////////////////////////////// */
/* ///////      DISCOUNT     /////////// */
/* ///////////////////////////////////// */
span.badge,
.badge-box b {
  /*background: #bfa980 none repeat scroll 0 0;*/
  /*border-radius: 15px;*/
  /*color: white;*/
  /*display: inline-block;*/
  /*font-weight: normal;*/
  /*min-width: 16px;*/
  /*padding: 1px 4px;*/
  /*text-align: center;*/
  font-weight: bold;
}
.product-discount.left {
  left: 0;
  right: auto;
}

/* ///////////////////////////////////// */
/* ///////      CATEGORY     /////////// */
/* ///////////////////////////////////// */
.category .category-description {
  width: 85%;
}
.products.grid-view .show-grid-view,
.products.list-view .show-list-view {
  display: inline-block;
}

.products.grid-view .show-list-view,
.products.list-view .show-grid-view {
  display: none;
}
.change-to-grid,
.change-to-list {
  padding: 6px 8px 4px 11px !important;
}

.products.grid-view .qtyminus:before,
.products.grid-view .qtyplus:before,
.products.list-view .qtyminus:before,
.products.list-view .qtyplus:before {
  left: auto !important;
}

/* ///////////////////////////////////// */
/* ///////      CHECKOUT     /////////// */
/* ///////////////////////////////////// */
button.checkout {
  border: none;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
}
.cancel.button.checkout {
  float: right;
  width: 140px;
  text-align: center;
  margin-right: 1em;
  padding: 10px !important;
  height: 44px;
  font-weight: bold;
}
.checkout-topbar > div.checkout-step {
  height: 40px;
  margin-bottom: 2em;
  padding-top: 10px;
  text-align: center;
  vertical-align: middle;
  color: #bfa980;
}
.checkout-topbar > div.checkout-step.active {
  background-color: #bfa980;
}
.checkout-topbar > div.checkout-step.active h2 {
  color: #fff;
}
.checkout-section.background-color.align {
  position: relative;
  left: -10px;
}
.checkout-section span {
  color: #fff !important;
}
.checkout-section.background-color a.checkout-step {
  color: white;
}
.checkout-section a.checkout-step {
  color: #888;
}

.checkout-section.done span {
  background-color: #548b54;
}
.checkout-section.not-done a {
  cursor: default !important;
}
.checkout-content .half {
  width: 47.2%;
}
.checkout-section {
  margin-left: 0px !important;
  margin-right: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.checkout-section.cart {
  padding: 0 !important;
  /*margin-left: -10px;*/
}
/*.checkout-section*/
.almost-fullwidth {
  width: 97.7% !important;
  display: inline-block;
}

.help-block-box {
  display: block;
  float: left;
  font-size: 10px;
  height: 0;
  margin-top: -5px;
  width: 100%;
}
.help-block.error {
  color: #e74c3c;
  display: inline-block;
  position: relative;
  top: -16px;
  float: left;
}
.discount-green {
  color: #278140;
}
b.req {
  font-size: 20px;
  color: #e74c3c;
  line-height: 0;
}
.table-box .checkout-content {
  margin-left: 11px;
  width: 97%;
}
#deliveryForm small.select-info {
  height: 30px;
  margin-bottom: -30px;
  position: relative;
  width: 100%;
}
small.select-info {
  float: left;
  position: relative;
  top: -20px;
  left: 17px;
}
.address-form-postcode-input {
  width: 60% !important;
}

.left-grey td:first-child {
  background: #eee none repeat scroll 0 0;
  border-bottom: 1px solid #ddd;
  font-weight: bold;
  width: 40%;
}
.half.block {
  clear: both;
  display: block;
  float: left;
  min-height: 40px;
  width: 47%;
}
.v-buttons .qtyplus,
.v-buttons .qty,
.v-buttons .qtyminus {
  clear: both;
  display: block;
}

/* ///////////////////////////////////// */
/* ///////       ABOUT       /////////// */
/* ///////////////////////////////////// */
#about-container img.text-image {
  clear: none;
  min-width: 33%;
  max-width: 400px;
  margin-top: 1em;
  margin-bottom: 5em;
}
#about-container img.text-image.text-image-left {
  margin-right: 3em;
  float: left;
}
#about-container img.text-image.text-image-right {
  margin-left: 3em;
  float: right;
}

/* ///////////////////////////////////// */
/* ///////       FOOTER      /////////// */
/* ///////////////////////////////////// */
.footer-address > p {
  margin-bottom: 0 !important;
}
#footer-quick-links li {
  float: left;
  margin-right: 1.1em;
}
#footer-quick-links > li::after {
  content: "|";
  left: 6px;
  position: relative;
}

#footer-quick-links > li:last-child::after {
  content: "" !important;
}
#footer-quick-links > li > a {
  font-weight: 200 !important;
}
#footer a:hover,
#footer-bottom a:hover {
  color: white !important;
}

/* ///////////////////////////////////// */
/* ///////    OVERRIDES      /////////// */
/* ///////////////////////////////////// */
br {
  clear: both;
  margin-bottom: 15px;
  display: block;
}
p br {
  margin-bottom: 3px;
}
h2 {
  margin: 1em auto 0.5em;
}
b {
  font-weight: bold !important;
}
#not-found b {
  font-weight: bold;
}
#not-found h2 {
  margin: 0 auto !important;
}
input.ng-invalid.ng-touched,
textarea.ng-invalid.ng-touched,
.ng-submitted input.ng-invalid,
.ng-submitted textarea.ng-invalid {
  border: 1px solid #e74c3c;
}
.magnific-popup-custom {
  background: #fff none repeat scroll 0 0;
  margin: 40px auto;
  max-width: 800px;
  padding: 30px 40px 35px;
  position: relative;
  text-align: left;
}
.stacktable tr {
  position: relative;
}
.titlebar {
  padding: 0 0 20px;
  margin-bottom: 30px;
}
.list-1 li::before,
.list-2 li::before,
.list-3 li::before,
.list-4 li::before {
  margin: 0 7px 0 -20px;
  position: absolute;
}
.list-1 li,
.list-2 li,
.list-3 li,
.list-4 li {
  margin: 7px 0 7px 20px;
}
#googlemaps {
  top: 1em;
  height: 300px;
}
.top-search input {
  width: 140px;
}
a.button {
  /*text-transform: none !important*/
}
.discount-code-form #discount-code,
.discount-code-form .button.color {
  width: auto !important;
}
.checkout-content .half.first {
  padding: 0 24px 0 0;
}
.checkout-content .half.second {
  float: right;
}

/* PURCHASE FORM */
------------------------------------- */ #purchase fieldset div {
  margin-bottom: 15px;
}

#purchase fieldset div input,
#purchase fieldset div label.button {
  width: 240px;
}

#purchase textarea {
  width: auto;
  min-width: 97.5%;
  min-height: 180px;
  resize: vertical;
}

#purchase input,
#purchase textarea,
#purchase label.button,
#purchase select {
  -webkit-transition: background 200ms ease-in-out,
    border-color 200ms ease-in-out;
  -moz-transition: background 200ms ease-in-out, border-color 200ms ease-in-out;
  -o-transition: background 200ms ease-in-out, border-color 200ms ease-in-out;
  -ms-transition: background 200ms ease-in-out, border-color 200ms ease-in-out;
  transition: background 200ms ease-in-out, border-color 200ms ease-in-out;
}

#purchase input:focus #purchase textarea:focus,
#purchase label.button:focus,
#purchase select:focus {
  outline: none;
  position: relative;
  z-index: 5;
}

#purchase input.error,
#purchase textarea.error,
#purchase select.error {
  border: 1px solid #ffeceb;
  background: #ffeceb;
}

#purchase label {
  margin-bottom: 5px;
}

#purchase label span {
  color: #e24747;
}
input[type="date"] {
  padding: 10px 12px;
  outline: none;
  font-size: 13px;
  color: #999;
  margin: 0;
  max-width: 100%;
  display: block;
  background: #eee;
  border: 1px solid #eee;
  font-weight: 600;
}
input[type="date"]:focus {
  border: 1px solid #f2f2f2;
  background: #f2f2f2;
  color: #808080;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  -ms-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

/* ///////////////////////////////////// */
/* ///////     RESPONSIVE    /////////// */
/* ///////////////////////////////////// */
@media screen and (max-width: 990px) {
  .checkout-section > span {
    padding: 3px 12px;
  }
  .checkout-section > a.checkout-step {
    font-size: 13px;
  }
  .address-form-postcode-input {
    width: 58% !important;
  }
}
@media screen and (min-width: 990px) {
  .one-fifth.columns,
  .one-fifth.column {
    width: 17.35%;
  }
}
@media screen and (min-width: 768px) {
  /* #call-us {
        width: 55%;
    } */
  #call-us a {
    float: right;
  }
  #navbar_cartandsearch {
    margin-top: 50px;
  }
  .home_page_product_description {
    padding-left: 3em;
  }
  .checkout-section,
  .checkout-section.cart {
    /*margin-left: -10px;*/
  }
  .getquote_button_container {
    margin-right: 2.5em !important;
  }
  #deliveryForm .half-width-input,
  #paymentForm .half-width-input {
    width: 86.5% !important;
  }
  #deliveryForm .half-width-select,
  #paymentForm .half-width-select {
    width: 48% !important;
  }
  #deliveryForm .half-width-input.right,
  #deliveryForm .half-width-select.right,
  #paymentForm .half-width-input.right,
  #paymentForm .half-width-select.right {
    float: right !important;
  }
  .one-fifth.columns,
  .one-fifth.column {
    width: 17.9%;
  }
}
@media screen and (max-width: 767px) {
  #my-user {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  ul.top-bar-menu li.contact-info {
    font-size: 10px;
  }
  .button.qvw {
    /* Quick View button...? */
    display: none;
  }
  .show-on-small {
    display: block;
  }
  .hide-on-small {
    display: none;
  }
  .half {
    width: 100% !important;
  }
  #cart,
  #my-user {
    /* margin-left: 0 !important; */
    margin-bottom: 10px !important;
  }
  #call-us {
    float: right;
    margin-left: 10px;
  }
  #call-us a {
    font-size: 22px;
  }
  .checkout-section,
  .checkout-section.cart {
    margin-left: 0;
  }

  .getquote_button_container {
    margin-right: 0em;
  }
  .cancel.button {
    margin-right: 0 !important;
  }
  #navbar_cartandsearch .top-search input {
    width: 183px !important;
  }
  #searchpage-searchform {
    width: 100%;
  }
  #searchpage-searchform input {
    float: left;
    width: 83.8%;
  }
  .product-button {
    bottom: 44px;
    opacity: 1;
  }
  .product:hover .product-button {
    transform: translateY(44px);
  }

  /*.one-fifth.columns, .one-fifth.column {*/
  /*width: 100%;*/
  /*}*/
}
@media screen and (max-width: 480px) {
  .show-on-mini {
    display: block;
  }
  .hide-on-mini {
    display: none;
  }
  .button.adc {
    margin-left: 0 !important;
  }
  #searchpage-searchform input {
    width: 77%;
  }
  nav.top-search {
    float: left !important;
  }
  #call-us {
    float: right;
    margin-left: 0;
  }
  .checkout-content select,
  .checkout-content input {
    margin: 0 0 15px -3px !important;
    width: 93%;
  }
  #navbar_cartandsearch {
    /*margin-top: 1em;*/
  }
  #navbar_cartandsearch .top-search input {
    width: 111px !important;
  }
  .stackable input.qty {
    margin-bottom: 0 !important;
    width: 25px;
    clear: both;
    display: block;
    left: 3px;
    position: relative;
  }
  .stackable .qtyminus {
    display: block;
    clear: both;
    float: none;
  }
  section.post-content header.meta {
    margin-left: 70px;
  }
  .post-content {
    margin: 0 0 0 0;
  }
  .payment-icons {
    margin: 25px 0 25px -5px !important;
  }
  .plusminusform {
    margin-bottom: 25px;
  }
  .address-form-postcode-input {
    width: 57% !important;
  }
}

.product section {
  padding: 18px 0 17px 0;
  text-align: center;
  background-color: #f6f6f6;
  height: 175px;
}

.btn-next {
  background-color: #548b54 !important;
  padding: 10px !important;
  height: 44px;
  width: 240px !important;
  float: right;
  text-align: center;
  font-size: 14px !important;
}
.btn-next:hover {
  background-color: #495d7f !important;
}

.qty-box-payment {
  width: 30px;
  float: right;
  text-align: center;
}


.lds-dual-ring {
    width: 40px;
    height: 40px;
    float: right;
}
.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 25px;
    height: 25px;
    margin: 8px;
    border-radius: 50%;
    border: 2px solid #495d7f;
    border-color: #495d7f transparent #495d7f transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}

.quote-icons {
    border-radius: 20px;
    width: 20px;
    padding: 3px;
}
@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}